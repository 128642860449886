import revive_payload_client_RbpyWCdpim from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_u7vsu36ojyiuzycnbwnn5xvsqu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jg7vHVjCUu from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_u7vsu36ojyiuzycnbwnn5xvsqu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_YA5Vp3YOOj from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_u7vsu36ojyiuzycnbwnn5xvsqu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jGdfRy9abk from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.1_sass@1.80_b45vvqy3sv6sphhlfurrdaltdm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_y26tzvcze3 from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_u7vsu36ojyiuzycnbwnn5xvsqu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Yld2L6vPaz from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_u7vsu36ojyiuzycnbwnn5xvsqu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_pk7xNVZkzu from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_u7vsu36ojyiuzycnbwnn5xvsqu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Ic80uI5YZT from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_u7vsu36ojyiuzycnbwnn5xvsqu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_11FD8S78Nu from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.2_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_zUbHDjlmIm from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_u7vsu36ojyiuzycnbwnn5xvsqu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import session_client_n0fehtih0R from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt-auth-utils@0.5.1_magicast@0.3.5_rollup@4.24.2_webpack-sources@3.2.3/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import plugin_oqR17PtNHw from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@parcel+watcher@2.4.1_@types+node@22.8.1_graphql-tag@2.12.6_graphq_bf5e4v5th65z7fmjuvedh2inz4/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import datadog_client_cfAAU11B0P from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/datadog.client.ts";
import ks_toast_client_iF5eIzTVpP from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/ks-toast.client.ts";
import error_handler_kEP5FliEXj from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/error-handler.ts";
import vue_masonry_wall_13R6vu58Yn from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/vue-masonry-wall.ts";
import vue_query_wrmMkNpEpe from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/vue-query.ts";
export default [
  revive_payload_client_RbpyWCdpim,
  unhead_jg7vHVjCUu,
  router_YA5Vp3YOOj,
  _0_siteConfig_jGdfRy9abk,
  payload_client_y26tzvcze3,
  navigation_repaint_client_Yld2L6vPaz,
  check_outdated_build_client_pk7xNVZkzu,
  chunk_reload_client_Ic80uI5YZT,
  plugin_vue3_11FD8S78Nu,
  components_plugin_KR1HBZs4kY,
  prefetch_client_zUbHDjlmIm,
  session_client_n0fehtih0R,
  plugin_oqR17PtNHw,
  datadog_client_cfAAU11B0P,
  ks_toast_client_iF5eIzTVpP,
  error_handler_kEP5FliEXj,
  vue_masonry_wall_13R6vu58Yn,
  vue_query_wrmMkNpEpe
]