import type { CartDto, CartItemDto } from "~/types/core/ecommerce/cart";
import type { ProductVariant } from "~/types/product-catalogue";
import type { RouteLocationNormalized } from "vue-router";
import * as v from "valibot";
import type { PacketVariant } from "~/types/universe";
import type { CalculatedPrices } from "~/types/pricing";
import type { CheckoutResponse } from "~/types/core/ecommerce/checkout";

export type SimpleCartItem = {
  isbn: ISBN;
  amount: number;
  fixedAmount: boolean;
  name: string | undefined;
  cover?: string;
  description?: string;
  price?: Exclude<CalculatedPrices, "NOT_APPLICABLE">;
  type: string;
} & (
  | {
      isTrial: true;
      id: ISBN; // Trials are not properly stored in the cart, so they have no UUID. We use the ISBN as a unique identifier instead.
    }
  | {
      isTrial: false;
      id: UUID;
    }
);

export type CartItemId = SimpleCartItem["id"];

export type MetadataVariant<TVariant extends string = string> = Pick<
  ProductVariant<TVariant>,
  | "title"
  | "name"
  | "isbn"
  | "description"
  | "cover"
  | "formats"
  | "sku"
  | "subtitle"
  | "fixedAmount"
  | "prices"
  | "tax"
  | "subscriptionsPlan"
  | "isTrial"
>;

export interface NewCartItem {
  variant: MetadataVariant;
  amount: number;
}

export interface AddOptions {
  route: RouteLocationNormalized;
  onSuccess?: () => void;
  onTemp?: () => void;
}

export interface CartSummary {
  total: number;
  discount: number;
  tax: number;
}

// Change this key if tempCartItemSchema is changed in a non-backwards compatible way
const TEMP_CART_KEY = "temp-cart-v1";
const tempCartItemSchema = v.pipe(
  v.nullable(v.string()),
  v.transform((it) => (typeof it === "string" ? JSON.parse(it) : it)),
  v.array(
    v.object({
      isbn: isbnSchema,
      amount: v.pipe(v.number(), v.minValue(1)),
    }),
  ),
);

export type TempCartItem = v.InferOutput<typeof tempCartItemSchema>[number];

export const useCartStore = defineStore("cart", () => {
  const tempCart = useSessionStorage(TEMP_CART_KEY, [], {
    serializer: {
      read: (input) => v.parse(tempCartItemSchema, input),
      write: JSON.stringify,
    },
  });

  const trial = useSessionStorage<ISBN | null>("trial", null);

  const remoteCart = ref<CartDto>();

  const cartApi = useCartApi();

  const checkoutApi = useCheckoutApi();

  const auth = useAuthorizeStore();

  const productCatalogue = useMetadata();

  const totStudents = useTotStudents();

  const items = computed<SimpleCartItem[]>(() => {
    if (trial.value) {
      const variant = productCatalogue.getVariant(trial.value);
      // If we have a trial set, we only show that.
      if (variant) {
        return [
          {
            id: variant.isbn,
            isbn: variant.isbn,
            amount: 1,
            fixedAmount: true,
            isTrial: true,
            name: variant.title || variant.name || undefined,
            description: variant.description,
            cover: variant.cover?.img,
            type: "Prøvelisens",
          } satisfies SimpleCartItem,
        ];
      }
    }

    if (!remoteCart.value) {
      return [];
    }

    const itemMapper = createSimpleCartItemMapper(
      totStudents.value,
      auth.user?.source,
    );

    return remoteCart.value.cartItems.map((item) => {
      const variant = productCatalogue.getVariant(item.productId);
      return itemMapper(item, variant);
    });
  });

  const summary = computed(() =>
    items.value.reduce<CartSummary>(
      (sum, item) => {
        const price = item.price;
        if (price) {
          sum.tax += item.amount * (price.actual.incVat - price.actual.exVat);

          const amount = item.amount * price.actual.incVat;
          sum.total += amount;

          if (price.discounted) {
            sum.discount += item.amount * price.original.incVat - amount;
          }
        }

        return sum;
      },
      {
        total: 0,
        discount: 0,
        tax: 0,
      },
    ),
  );

  const count = computed(() => items.value.length);

  const isEmpty = computed(() => count.value === 0);

  const toast = inject(keys.toast, null);

  /**
   * Update the amount of a cart item. Will be set eagerly before the API call, and reverted if the call fails.
   *
   * @param itemOrId - The uuid of the cart item to update, or the actual item
   * @param newAmount - The new amount to set
   */
  async function updateAmount(
    itemOrId: string | CartItemDto,
    newAmount: number,
  ) {
    if (!auth.canUseCart) {
      return;
    }

    let item: CartItemDto | undefined;
    if (typeof itemOrId === "string") {
      if (!remoteCart.value) {
        return;
      }

      item = remoteCart.value.cartItems.find((i) => i.uuid === itemOrId);
    } else {
      item = itemOrId;
    }

    if (item) {
      const oldAmount = item.amount;

      if (oldAmount === newAmount) {
        return;
      }

      item.amount = newAmount;

      try {
        await cartApi.updateCartItem({
          ...item,
          amount: newAmount,
        });
      } catch {
        item.amount = oldAmount;
        toast?.error(
          "Kunne ikke oppdatere antall på varen, ta kontakt med kundeservice om feilen vedvarer.",
        );
      }
    }
  }

  // Update the amount of fixed amount items when the total number of students changes
  watch(totStudents, async (newValue, oldValue) => {
    if (!remoteCart.value || oldValue === newValue) {
      return;
    }

    for (const item of remoteCart.value.cartItems) {
      const variant = productCatalogue.getVariant(item.productId);
      if (variant && variant.fixedAmount) {
        await updateAmount(item, newValue);
      }
    }
  });

  const currentRoute = ref<ReturnType<typeof useRoute>>();

  watch([remoteCart, trial], async ([newCart, newTrial]) => {
    let skus = newCart?.cartItems?.map((it) => it.productId) ?? [];
    if (newTrial) {
      skus.push(newTrial);
    }

    skus = skus.filter((it) => !productCatalogue.contains(it));

    const route = currentRoute.value ?? useRoute();
    const variants = await fetchMetadata(route, skus ?? []);

    productCatalogue.setMetadata(variants);
  });

  const { origin } = useRequestURL();

  return {
    count,
    isEmpty,
    summary,
    items,

    // To avoid calling useRoute in middleware
    setCurrentRoute(route: ReturnType<typeof useRoute>) {
      currentRoute.value = route;
    },

    /**
     * Fetch the remote cart. If the cart is not already fetched, it will be fetched from the API.
     */
    async fetchRemoteCart() {
      if (!auth.canUseCart) {
        return;
      }

      const newCart = await cartApi.getActiveOrNewCart();
      if (newCart) {
        remoteCart.value = newCart;
      }
    },

    /**
     * Refresh the remote cart. If the cart is not already fetched, it will be fetched from the API.
     */
    async refreshRemoteCart() {
      if (!remoteCart.value && auth.canUseCart) {
        await this.fetchRemoteCart();
      }
    },

    /**
     * Reset the cart. This will clear the cart and the trial.
     *
     * Note: It will not _delete_ the remote cart, use the `clear` method for that.
     */
    $reset() {
      remoteCart.value = undefined;
      trial.value = null;
      tempCart.value = [];
    },

    /**
     * Add a temporary item to the cart. This is intended to be used when the user is logged out, and then moved to the remote cart as soon as the user logs in.
     *
     * @param variant - The variant to add
     * @param amount - The amount to add
     */
    addTempItem({ variant, amount }: NewCartItem) {
      if (import.meta.server) {
        return;
      }

      const values = new Map([[variant.isbn, amount]]);
      if (!variant.isTrial) {
        for (const temp of tempCart.value) {
          const tempVariant = productCatalogue.getVariant(temp.isbn);
          if (tempVariant) {
            const value = values.get(tempVariant.isbn) ?? 0;
            values.set(tempVariant.isbn, value + temp.amount);
          }
        }
      }

      tempCart.value = Array.from(
        values,
        ([isbn, amount]): TempCartItem => ({ isbn, amount }),
      );
    },

    /**
     * Hydrate the temporary items in the cart. This is intended to be used when the user logs in, to move the temporary items to the remote cart.
     *
     * @param route - The route to use for fetching the product catalogue
     */
    async hydrateTempItems(route: RouteLocationNormalized) {
      if (import.meta.server) {
        return;
      }

      await this.refreshRemoteCart();

      if (!remoteCart.value) {
        return;
      }

      for (const item of tempCart.value) {
        const remote = remoteCart.value.cartItems.find(
          (it) => it.productId === item.isbn,
        );

        const variant = productCatalogue.getVariant(item.isbn);

        if (variant) {
          if (remote && !variant.fixedAmount) {
            await updateAmount(remote, remote.amount + item.amount);
          } else if (!remote) {
            await this.add(
              {
                variant,
                amount: variant.fixedAmount ? totStudents.value : item.amount,
              },
              {
                route,
                onSuccess() {},
              },
            );
          }
        } else {
          // TODO: Should we remove the item from the cart if the variant is not found?
        }
      }

      tempCart.value = [];
    },

    /**
     * Add an item to the cart. If the cart is remote, the item will be added eagerly, before the API is called to add the item. If the API call fails, the item will be removed from the cart.
     *
     * @param item - The item to add to the cart
     * @param route - The route to use for fetching the product catalogue
     * @param onSuccess - A callback to call if the item is successfully added to the cart
     * @param onTemp - A callback to call if the user is not authenticated
     */
    async add(
      { variant, amount }: NewCartItem,
      { route, onSuccess, onTemp }: AddOptions,
    ) {
      if (variant.fixedAmount && amount !== totStudents.value) {
        amount = totStudents.value;
      }

      if (!auth.loggedIn) {
        this.addTempItem({ variant, amount });
        if (onTemp) {
          onTemp();
        } else {
          navigateToLogin(route);
        }
        return true;
      }

      if (!auth.canUseCart) {
        return;
      }

      if (variant.isTrial) {
        trial.value = variant.isbn;
        toast?.success("Prøvelisens lagt til i handlekurven");
        return;
      }

      trial.value = null;

      await this.refreshRemoteCart();

      if (!remoteCart.value) {
        return;
      }

      const existing = remoteCart.value.cartItems.find(
        (i) => i.productId === variant.isbn,
      );

      if (existing) {
        if (!variant.fixedAmount) {
          existing.amount += amount;

          try {
            await cartApi.updateCartItem(existing);
            toast?.success(
              "Antall i handlekurven oppdatert til " + existing.amount,
            );
            return true;
          } catch {
            existing.amount -= amount;
            toast?.error(
              "Kunne ikke legge til varen i handlekurven, ta kontakt med kundeservice om feilen vedvarer.",
            );
          }
        }

        toast?.info("Varen ligger allerede i handlekurven.");
        return false;
      }

      const relatedVariants = await productCatalogue.getRelatedVariants(
        route,
        variant,
      );

      if (relatedVariants) {
        if (
          remoteCart.value.cartItems.some(
            (it) => relatedVariants.parent?.isbn === it.productId,
          )
        ) {
          // Can't add a child variant if the parent is already in the cart
          toast?.info(
            "Du har allerede lagt til en variant av dette produktet i handlekurven.",
          );
          return false;
        }

        if (relatedVariants.children.length > 0) {
          const toBeRemoved = remoteCart.value.cartItems.filter((it) =>
            relatedVariants.children.some(
              (child) => child.isbn === it.productId,
            ),
          );

          toBeRemoved.forEach((it) => this.remove(it.uuid));
        }
      }

      const newItem: Omit<CartItemDto, "uuid"> = {
        productId: variant.isbn,
        amount,
        productName: variant.title,
        subscriptionPlan: variant.subscriptionsPlan?.identifier,
        subscriptionPeriod: variant.subscriptionsPlan?.period?.id,
      };

      try {
        const newCart = await cartApi.addItemToCart(newItem);

        if (newCart) {
          remoteCart.value = newCart;
        }

        if (onSuccess) {
          onSuccess();
        } else {
          toast?.success("Varen er lagt til i handlekurven");
        }
        return true;
      } catch {
        toast?.error(
          "Kunne ikke legge til varen i handlekurven, ta kontakt med kundeservice om feilen vedvarer.",
        );
        return false;
      }
    },

    /**
     * Remove an item from the cart. If the cart is remote, the item will be removed eagerly, before the API is called to remove the item. If the API call fails, the item will be added back to the cart.
     *
     * @param itemId - The uuid of the cart item to remove, or the isbn of the trial item
     */
    async remove(itemId: UUID | ISBN) {
      if (!auth.canUseCart) {
        return;
      }

      if (trial.value === itemId) {
        trial.value = null;
        return;
      }

      if (!remoteCart.value) {
        return;
      }

      const old = remoteCart.value.cartItems;
      const item = old.find((i) => i.uuid === itemId);

      if (!item) {
        return;
      }

      remoteCart.value.cartItems = old.filter((i) => i !== item);

      try {
        // FIXME: Check that itemId looks like a UUID
        const newCart = await cartApi.deleteItemFromCart(itemId as UUID);
        if (newCart) {
          remoteCart.value = newCart;
        }
      } catch {
        remoteCart.value.cartItems = old;
        toast?.error(
          "Kunne ikke fjerne varen fra handlekurven, ta kontakt med kundeservice om feilen vedvarer.",
        );
      }
    },

    /**
     * Checkout the cart. If the user is not authenticated, the user will be redirected to the login page.
     *
     * Will also create a contact in Hubspot if the user is authenticated.
     *
     * @param route - The route to use for navigating
     * @param portalId - The Hubspot portal ID, fetched from the runtime config
     */
    async checkout(route: RouteLocationNormalized, portalId: string) {
      if (!auth.loggedIn) {
        await navigateToLogin(route);
        return;
      }

      if (!auth.canUseCart) {
        return;
      }

      function requireOrganizationNumber() {
        const organizationNumber = auth.user?.selectedOrganization?.number;

        if (!organizationNumber) {
          toast?.error({
            message:
              "Vi fant ikke en organisasjon knyttet til din bruker. Vennligst ta kontakt med kundeservice.",
          });
          throw new Error("No organization number found");
        }

        return organizationNumber;
      }

      try {
        await $fetch("/api/hubspot/contact", {
          method: "POST",
          headers: await auth.headers(),
        });
        // FIXME: Don't think this actually updates the frontend user
      } catch (e) {
        // TODO: If error is INVALID_EMAIL, show modal for updating email
        handleError(e, "Error creating contact in Hubspot", false);
      }

      let res: boolean | CheckoutResponse = true;

      if (trial.value) {
        const old = trial.value;
        try {
          await checkoutApi.initiateCheckoutSession("CRYSTALLIZE_SKOLE", {
            productId: trial.value,
            paymentSource: "TRIAL",
            organizationNumber: requireOrganizationNumber(),
          });

          trial.value = null;
          return true;
        } catch {
          toast?.error(
            "Kunne ikke starte prøveperiode, ta kontakt med kundeservice om feilen vedvarer.",
          );
          trial.value = old;
          return false;
        }
      } else if (!auth.user?.hubspot) {
        toast?.error({
          message:
            // FIXME: Different error message for consumers
            "Vi fant ikke en kunde knyttet til din organisasjon. Vennligst ta kontakt med kundeservice.",
          duration: 5000,
        });
        return false;
      } else {
        const old = remoteCart.value;
        try {
          if (auth.isConsumer) {
            const checkoutResponse = await cartApi.checkout({
              customerType: "USER",
              paymentSource: "VIPPS",
              customerOriginId: portalId,
              redirectUrl: `${origin}/takk?source=vipps`,
            });

            res = checkoutResponse ?? false;
          } else {
            if (!auth.user?.hubspot?.referenceNumber) {
              toast?.error({
                message:
                  "Vi mangler referansenummer for organisasjonen din. Dette må legges til på Min side for å få gjennomført kjøp.",
                persistent: true,
                buttons: [
                  {
                    text: "Gå til Min side",
                    onClick: () => {
                      navigateTo("/minside#referansenummer");
                    },
                  },
                ],
              });
              return false;
            }

            const organizationNumber = requireOrganizationNumber();

            await cartApi.startSubscriptions({
              customerType: "ORGANIZATION",
              customerOrganizationNumber: organizationNumber,
              buyerOrganizationNumber: organizationNumber,
              paymentSource: "INVOICE",
              customerNumber: `1-${auth.user.hubspot.customerId}`,
              customerOriginId: portalId,
              referenceNumber: auth.user.hubspot.referenceNumber,
            });
            res = true;
          }

          if (res) {
            remoteCart.value = undefined;
          }
        } catch (e) {
          toast?.error(
            "Kunne ikke starte abonnement, ta kontakt med kundeservice om feilen vedvarer.",
          );

          handleError(e, "Error starting subscription", false);
          remoteCart.value = old;
          return false;
        }

        try {
          await this.fetchRemoteCart();
          return res;
        } catch (e) {
          handleError(e, "Error fetching remote basket", false);
          return res;
        }
      }
    },

    updateAmount,

    deleteCart: () => cartApi.deleteCart(),
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot));
}

function useMetadata() {
  const metadata = useState(
    "cart-metadata",
    () => new Map<ISBN, MetadataVariant>(),
  );

  const packetVariants = useState<PacketVariant[]>("packet-variants", () => []);

  return {
    contains(isbn: ISBN) {
      return metadata.value.has(isbn);
    },
    getVariant(isbn: ISBN) {
      return metadata.value.get(isbn);
    },
    setMetadata(variants: MetadataVariant[]) {
      for (const variant of variants) {
        metadata.value.set(variant.isbn, variant);
      }
    },
    async getRelatedVariants(
      route: RouteLocationNormalized,
      variant: MetadataVariant,
    ) {
      if (!isPacketVariant(variant)) {
        return null;
      }

      if (packetVariants.value.length === 0) {
        const results = await GqlGetCatalogue({
          path: "/aunivers",
          language: "no-nb",
          version: useCrystallizeVersion(route),
        });

        const products = results.catalogue ? toProducts(results.catalogue) : [];

        packetVariants.value = products
          .filter(isPacket)
          .flatMap((it) => it.variants);
      }

      const variants = packetVariants.value;

      const self = variants.find((it) => it.sku === variant.sku);

      if (!self) {
        return undefined;
      }

      let parent: PacketVariant | undefined;
      const children: PacketVariant[] = [];
      for (const variant of variants) {
        if (variant.relatedSubscription?.includes(self.sku)) {
          parent = variant;
        } else if (
          self.relatedSubscription?.includes(variant.sku) &&
          variant.included
        ) {
          children.push(variant);
        }
      }

      return { parent, children };
    },
  };
}

async function fetchMetadata(route: RouteLocationNormalized, skus: string[]) {
  if (skus.length === 0) {
    return [];
  }

  const res = await GqlGetCartMetadata({
    language: "no-nb",
    skus,
    version: useCrystallizeVersion(route),
  });

  return (
    res.productVariants?.filter(truthy).map((it) => {
      const slug = pathToSlug(it.product?.path ?? "");
      const tax = getTax(it.product);
      const type =
        it.product?.shape?.identifier === "aunivers-abonnement"
          ? "packet"
          : "single-product";

      if (type === "packet") {
        return toPacketVariant(it, {
          slug,
          type,
          tax,
        });
      } else {
        return toSingleProductVariant(it, {
          slug,
          type,
          tax,
          title: it.product?.name ?? "",
          formats: [],
          biblioFormats: [],
        });
      }
    }) ?? []
  );
}
