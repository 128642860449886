import validate from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_u7vsu36ojyiuzycnbwnn5xvsqu/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/middleware/auth.global.ts";
import manifest_45route_45rule from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_u7vsu36ojyiuzycnbwnn5xvsqu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "check-auth": () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/middleware/check-auth.ts")
}