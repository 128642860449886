import {
  type DehydratedState,
  type VueQueryPluginOptions,
  QueryCache,
} from "@tanstack/vue-query";
import {
  VueQueryPlugin,
  QueryClient,
  hydrate,
  dehydrate,
} from "@tanstack/vue-query";
import { broadcastQueryClient } from "@tanstack/query-broadcast-client-experimental";

export default defineNuxtPlugin((nuxt) => {
  const vueQueryState = useState<DehydratedState | null>("vue-query");

  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 1000 * 60 * 5 } },
    queryCache: new QueryCache({
      onError(error, query) {
        handleError(
          error,
          "Query failed " + JSON.stringify(query.queryKey),
          false,
        );
      },
    }),
  });

  const options: VueQueryPluginOptions = {
    queryClient,
  };

  nuxt.vueApp.use(VueQueryPlugin, options);

  if (import.meta.server) {
    nuxt.hooks.hook("app:rendered", () => {
      vueQueryState.value = dehydrate(queryClient);
    });
  }

  if (import.meta.client) {
    // Keep the client in sync with other tabs
    broadcastQueryClient({ queryClient });
    hydrate(queryClient, vueQueryState.value);
  }
});
